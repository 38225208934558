import { Box } from '@mui/material';
import useResponsive from 'hooks/useResponsive';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Main from './Main';
import Header from './header';
import NavVertical from './nav/NavVertical';

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const isDesktop = useResponsive('up', 'lg');
  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;
  return (
    <>
      <Header onOpenNav={handleOpen} />
      {!isDesktop && renderNavVertical}
      <Box
        bgcolor="background.neutral"
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        <Main>
          <Outlet />
        </Main>
      </Box>
      <Footer />
    </>
  );
}
